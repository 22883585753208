import React, {Component} from 'react'
import Link from 'gatsby-link'
import staticdata from '../../staticdata.json'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'
import { Controller, Scene } from 'react-scrollmagic'

import Layout from "../components/layout"

import Circle from '../components/circle.js'
import HeadPage from '../components/headPage'
import CardProject from '../components/cardProject'
import TileProject from '../components/tileProject'
import Cell from '../components/cell'
import HeroImage from '../components/heroImage'
import BackgroundText from '../components/backgroundText.js'

import sevenhugsHeroReveal from '../images/sevenhugs-thumb-gif3.gif'
import CustomUI from '../images/Custom-UI.png'
import triberMotion from '../images/triberMotion.gif'
import agorizeThumbnailProduct from '../images/ChallengeBuilder-video.gif'
import agorizeThumbnailSystem from '../images/agorize-thumbnail.png'
import doctolibThumbnail from '../images/doctolib-thumbnail.gif'
import doctolibThumbnail2 from '../images/doctolib-thumbnail-2.png'
import livestormThumbnail from '../images/livestorm-thumbnail2.gif'
import livestormThumbnail1 from '../images/livestorm-thumbnail1.png'
import livestormThumbnail2 from '../images/livestorm-thumbnail2.png'
import seCollab3 from '../images/SE-Collab-3.png'
import banner01 from '../images/banner01.png'
import granularThumbnail from '../images/granular-thumbnail.png';


class IndexPage extends Component {

	constructor(props) {
		super(props);
	}

	render = () => {
	return (
	<Layout>
		<div> 
		<HeadPage/>

		<div className="CardsList">
		<Fade bottom cascade delay={550} duration={550} distance="1.6em" ssrFadeout>
			
			<a href="https://www.linkedin.com/in/alexandretrimoulet" target="_blank">
				<CardProject 
					caption="Livestorm — 2021-Today"
					title="Redesigning the event access flow to allow meeting use cases"
					role="Product Design · Design Systems"
					image={livestormThumbnail2}
					fakelink="Request access"

				/>
			</a>

			<a href="https://www.linkedin.com/in/alexandretrimoulet" target="_blank">
				<CardProject 
					caption="Livestorm — 2021-Today"
					title="Making the event room cutomizable"
					role="Product Design · Design Systems"
					image={livestormThumbnail}
					fakelink="Request access"

				/>
			</a>
			<a href="https://www.linkedin.com/in/alexandretrimoulet" target="_blank">
				<CardProject 
					caption="Doctolib — 2020-2021"
					title="Helping general practitioners prescribe medication during a consultation"
					role="Product Design · Design Systems"
					image={doctolibThumbnail2}
					fakelink="Request access"

				/>
			</a>
			<a href="https://www.linkedin.com/in/alexandretrimoulet" target="_blank">
				<CardProject 
					caption="Doctolib — 2020-2021"
					title="Allowing general practitioners to create invoices during home consultations"
					role="Product Design · Design Systems"
					image={doctolibThumbnail}
					fakelink="Request access"

				/>
			</a>
			</Fade>
		</div>
		

		
		
		<div className="Footer">
			<div className="Left">
				<p>
					Interested in working together?<br />
					<a href="https://www.linkedin.com/in/alexandretrimoulet/" target="_blank">Get in touch →</a>
				</p>
			</div>
			<div className="Right">
				<p className="SocialLinks">
					Find me on
					<a id="linkedin" href="https://www.linkedin.com/in/alexandretrimoulet" target="_blank"> LinkedIn</a>, 
					<a id="medium" href="https://medium.com/@AlexTrimoulet" target="_blank"> Medium</a>, 
					<a id="twitter" href="https://twitter.com/AlexTrimoulet" target="_blank"> Twitter</a>,
					<a id="soundcloud" href="https://soundcloud.com/osphoz/" target="_blank"> SoundCloud</a>,
					<a id="dribbble" href="https://dribbble.com/alexandret" target="_blank"> Dribbble</a>, 
					<a id="producthunt" href="https://www.producthunt.com/@alextrimoulet" target="_blank"> Product Hunt</a>,  & 
					<a id="instagram" href="https://instagram.com/alextrmlt" target="_blank"> Instagram</a>.
				</p>
			</div>
			<div className="developedBy">
				Portfolio designed & developed in React by Alex Trimoulet 💪🏻 <br />
				© All rights reserved 2022
			</div>
		</div>


	</div>
	</Layout>
)
}
}

export default IndexPage

